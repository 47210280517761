import registerMetric from '../../api/services/stats.service';
import {
  ERROR_CATCHER,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX,
  UNKNOWN_VALUE,
} from '../constants';
import { registerErrorMetrics } from '../utils/register-error-metrics';

const errorList = {
  'Script error': 'script_error',
  'Uncaught ReferenceError': 'uncaught_reference_error',
  'Uncaught TypeError': 'uncaught_type_error',
  'Uncaught SyntaxError': 'uncaught_syntax_error',
  'Uncaught RangeError': 'uncaught_range_error',
  'Uncaught Error: Minified React error #425': 'mismatch_error',
  'Uncaught Error: Minified React error #418': 'mismatch_error',
  'Uncaught Error: Minified React error #423': 'mismatch_error',
  'Uncaught Error: Text content does not match server-rendered HTML.': 'mismatch_error',
  'Uncaught Error: Hydration failed because the initial UI does not match what was rendered on the server.':
    'mismatch_error',
  'Uncaught Error: There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.':
    'mismatch_error',
  'Uncaught Error': 'uncaught_error',
  'Minified React error #418;': 'minified_418',
  'Minified React error #423;': 'minified_423',
  'Minified React error #425;': 'minified_425',
  'Unhandled Promise Rejection': 'unhandled_promise_rejection',
  'Cannot read properties of undefined': 'undefined_destructuring',
};

function mapErrorTypeTag(errorMessage) {
  if (!errorMessage) {
    return UNKNOWN_VALUE;
  }

  const foundError = Object.keys(errorList).find((error) => errorMessage.includes(error));

  return foundError ? errorList[foundError] : 'generic';
}

const loggedComponents = new Set();

// eslint-disable-next-line func-names
(function () {
  window.catchErrorBoundary = (metricDataConfig = {}) => {
    registerMetric(metricDataConfig);
  };

  function configureHandler() {
    window.addEventListener('error', (event) => {
      try {
        const errorType = mapErrorTypeTag(event.message);
        const componentName = window?.ERROR_COMPONENT_NAME || UNKNOWN_VALUE;
        const errorIdentifier = `${componentName}_${errorType}`;

        if (loggedComponents.has(errorIdentifier)) {
          return;
        }

        loggedComponents.add(errorIdentifier);

        registerErrorMetrics({
          errorType,
          errorCatcher: window?.ERROR_BOUNDARY || ERROR_CATCHER.JS_ERROR,
          errorStack: event.error.stack,
          errorMessage: event.error.message,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error sending error metric', error);

        registerErrorMetrics({
          key: `${SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX}.${SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES.SCRIPT_CATCH}`,
          errorType: 'sending_error_metric',
          errorCatcher: window?.ERROR_BOUNDARY || ERROR_CATCHER.JS_ERROR,
          errorStack: error.stack,
          errorMessage: error.message,
        });
      }
    });
  }

  configureHandler();
})();
